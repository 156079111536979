import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';

const routes: Routes =[

{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },{
    path: '',
    component: LoginComponent,
   },

 {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
  }]},
  {
    path: '**',
    redirectTo: 'login'
  }

   
//   {
//     path: '',
//     redirectTo: 'table-list',
//     pathMatch: 'full',
//   }, {
//     path: '',
//     component: TableListComponent,
//     },

//     {
//     path: '**',
//     redirectTo: 'table-list'
//   }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
