import { Component, OnInit } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule,} from '@angular/material/menu';
import {ApiService} from '../../api.service';
import { Router } from '@angular/router';
declare var $: any;

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  child: any
}
export const ROUTES: RouteInfo[] = [
 
 
  { path: '/internal_reporting', title: 'Internal reporting', icon: 'fa fa-trello', class: '', child: [
    { path: '/maindashboard', title: 'Dashboard', icon: 'fa fa-area-chart', class: '', child: [] },
    { path: '/KPIs', title: 'Monthly KPIs', icon: 'text_caps-small', class: '', child: [] },   
  ] },
  { path: '/table-list', title: 'Customers', icon: 'design_bullet-list-67', class: '', child: [
    { path: '/table-list', title: 'Customers', icon: 'design_bullet-list-67', class: '', child: []  },
    { path: '/Failed-registration', title: 'Failed Registrations',icon: 'text_caps-small', class: '', child: []},
    { path: '/manual-registration', title: 'Manual Registrations',icon: 'design_bullet-list-67', class: '', child: []},
    { path: 'sendgifts', title: 'Sent Gifts', icon: 'text_caps-small', class: '',child: []

    }
  ] },
  {
    path: '/notifications', title: 'Payment Management', icon: 'education_atom', class: 'dropdown-btn', child: [
    { path: '/notifications', title: 'Dashboard', icon: 'fa fa-tachometer', class: '', child: [] },
    { path: '/typography', title: 'All Goals', icon: 'text_caps-small', class: '', child: [] },
    { path: '/icons', title: 'Daily Payment', icon: 'education_atom', class: '', child: [] },
    
    ]
  },
  {
    path: '/roles', title: 'Roles & Permissions', icon: 'fa fa-trello', class: 'dropdown-btn',child: [
      { path: '/roles', title: 'Team Members', icon: 'education_atom', class:'', child:[]},
      { path: '/audit-trail', title: 'Audit Trail', icon: 'fa fa-tachometer' , class: '', child: []},
      { path: '/approvals', title: 'Approvals', icon: 'text_caps-small', class:'' ,child: []},
      { path: '/verification', title: 'Verification', icon: 'education_atom',class: '', child: []}
    ]
  },
  {
    path: '/change-password', title: 'Change Password', icon: 'fa fa-trello', class: '',child: []
  },

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  internal_dropdown:boolean=false;
  payment_dropdown: boolean;
  customer_dropdown:boolean=false;
  roles_dropdown:boolean=false;
  data: any;
  id: any;
  username: any;
  internal_dashboard: any;
  internal_kpi: any;
  payment_allgoals: any;
  payment_dailypay: any;
  payment_dashboard: any;
  customer_view: any;
  customer_failed: any;
  customer_edit: any;
  c:boolean;
  p:boolean;
  i:boolean;
  response: any={};
  admin_type: any;
  permission: boolean;
  team: boolean;
  audit: boolean;
  approves: boolean;
  sent_gifts: any;
  receive_payments: any;
  manuals: any;
  payment_sendgifts: any;
  payment_invoice: any;
  sendgifts: number;
  manual_registration: any;

  constructor(private router: Router,private _apiService: ApiService) { 
    
        
          // console.log("res",res[0])
          // this.id =  res[0].id;
          // this.username = res[0].username;
          // this.internal_dashboard = res[0].internal_dashboard;
          // this.internal_kpi = res[0].internal_kpi;
          // this.payment_allgoals = res[0].payment_allgoals;
          // this.payment_dailypay = res[0].payment_dailypay;
          // this.payment_dashboard = res[0].payment_dashboard;
          // this.customer_view = res[0].customer_view;
          // this.customer_failed = res[0].customer_failed;
          // this.customer_edit = res[0].customer_edit;
  
        
       
       
        // console.log(this.customer_edit)
//       // id: 2
// internal_dashboard: 1
// internal_kpi: 1
// password: "admin12345"
// payment_allgoals: 1
// payment_dailypay: 1
// payment_dashboard: 1
// customer_edit: 1
// customer_failed: 1
// customer_view: 1
      // });   
  

  }

  ngOnInit() {
    this.data = localStorage.getItem('data');
    console.log(JSON.parse(this.data));
    this.response = JSON.parse(this.data);
    console.log(this.response[0]);
           this.id =   this.response[0].id;
          this.username =  this.response[0].username;
          this.admin_type = this.response[0].admin_type;
          this.internal_dashboard =  this.response[0].internal_dashboard;
          this.internal_kpi =  this.response[0].internal_kpi;
          this.payment_allgoals =  this.response[0].payment_allgoals;
          this.payment_dailypay =  this.response[0].payment_dailypay;
          this.payment_dashboard =  this.response[0].payment_dashboard;
          this.customer_view =  this.response[0].customer_view;
          this.customer_failed =  this.response[0].customer_failed;
          this.customer_edit =  this.response[0].customer_edit;
          this.sent_gifts = this.response[0].sent_gifts;
          this.receive_payments = this.response[0].receive_payments;
          this.payment_sendgifts = this.response[0].payment_sendgifts;
          this.payment_invoice = this.response[0].payment_invoice;
          this.manual_registration = this.response[0].manual_registration;
          
      

    

    //************************ */
    if(this.admin_type == 'Super_user'){
        this.permission = true;
        this.team = true;
        this.audit = true;
        this.approves =true;
        this.manuals =true;
        
    }
    else if(this.admin_type == 'Sub-user'){
      this.permission = true;
      this.audit = true;
    }
    else{
      this.permission = false;

    }
    if(this.payment_sendgifts == 0 &&  this.payment_dailypay == 0 && this.payment_invoice == 0){
      this.p = false;
    }
    else{
      this.p = true;
    }

    if(this.customer_view == 0 && this.customer_failed == 0 && this.manual_registration ==0  && this.payment_allgoals == 0 && this.sent_gifts == 0 && this.receive_payments == 0){
      this.c = false;

    }
    else{
      this.c = true;
    }
    if( this.internal_dashboard == 0 && this.internal_kpi == 0){
      this.i = false;
    }
    else{
      this.i = true;
    }
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    console.log(this.menuItems)

    /* Loop through all dropdown buttons to toggle between hiding and showing its dropdown content - This allows the user to have multiple dropdowns without any conflict */
    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {

      });
    }
  }

  showNavDropdown(index) {

    var dropdown = document.getElementsByClassName("nav-item");
    
    var dropdownContent = $(dropdown[index]).find(".dropdown-container-" + index)
    
    if (dropdownContent[0].style.display === "block") {
      dropdownContent[0].style.display = "none";
    } else {
      dropdownContent[0].style.display = "block";
    }
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  };
  internal()
  {
    if(this.internal_dropdown==true){
      this.internal_dropdown=false;
    }
    else{
      this.internal_dropdown=true;
      this.payment_dropdown=false
      this.customer_dropdown=false;
      this.roles_dropdown=false;
    }
  }

  payment(){
    if(this.payment_dropdown==true){
      this.payment_dropdown=false
    }
    else{
      this.payment_dropdown=true;
      this.internal_dropdown=false
      this.customer_dropdown=false;
      this.roles_dropdown=false;
    }
  }

  roles(){
    if(this.roles_dropdown ==true){
      this.roles_dropdown = false;
    }
    else{
        this.roles_dropdown = true;
        this.internal_dropdown = false;
        this.customer_dropdown=false;
        this.payment_dropdown=false;
    }
  }

  customers(){
    if(this.customer_dropdown==true){
     this.customer_dropdown=false;
    } 
    else{
      this.customer_dropdown=true;
      this.internal_dropdown=false;
      this.payment_dropdown=false
      this.roles_dropdown=false;
    }   
  }
}
