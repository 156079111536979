import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  data:any={};

  constructor(private http:HttpClient) { }
  getData(url) {
        return this.http.get(url);
    }

    login(url,value){
      this.data = this.http.post(url,value);
      // this.permit();
      return this.http.post(url,value);
    }
    // permit()  {
     
      
    //     console.log(this.data)
    //     return this.data;
      
     
    // }
postData(url,id){
	return this.http.post(url,{r_id:id});
}
postId(url,id){
  return this.http.post(url,{id:id});
}

postIdDate(url,id,from_date,to_date){
  return this.http.post(url,{id:id,from_date,to_date});
}


// contributer
postDataid(url,id){
	return this.http.post(url,{id:id});
}

postDate(url,date){
	return this.http.post(url,{date:date});
}

post_Date(url,from_date,to_date){
	return this.http.post(url,{from_date:from_date,to_date:to_date});
}
Deactivate(url,id){
	return this.http.post(url,{customer_id:id});
 }
 Activate(url,id){
	return this.http.post(url,{customer_id:id});
 }

 sendFile(formData: any){ 
    
    // let baseUrl = 'http://127.0.0.1:8000/api'; 
    let url = 'https://app.krowdbox.com/api/uploadfile';     
    return this.http.post(url,formData)
  }
api(url,id){

	return this.http.post(url,{r_id:id})
}
public register(url,data){
  return this.http.post(url,data);

}

delBank(url,id){

	return this.http.post(url,{id:id})
}


}

        
