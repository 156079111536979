
import { Input, Component, Output, EventEmitter ,OnInit} from '@angular/core';
import { FormGroup, FormControl,ReactiveFormsModule } from '@angular/forms';
import {ApiService} from '../api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	data:any={};
	error:boolean=false;
	admin: any;
	user: string;
	value: any={};
	url= "https://app.krowdbox.com/api/";
	abc: any;
	permission: any={};
	customer_view: any;
	internal_dashboard: any;
	payment_dashboard: any;
	// dataSource:any;
  constructor(private router: Router,private _apiService: ApiService) { }

  ngOnInit() {
  	  }
 

  submit() {
	  console.log(this.data)
	  this.value['username'] = this.data.username;
	  this.value['password'] = this.data.password;
	  console.log(this.value)
	//   code for login api
	  this._apiService.login(this.url+"adminLogin",this.value).subscribe(
		res =>{
		   
		  this.data = res;
		  this.admin = this.data;
		  this.user = JSON.stringify(this.admin)
		  console.log(JSON.parse(this.user))
		   this.value = JSON.parse(this.user);
		   console.log(this.value);
		    console.log(typeof(this.value));
		   localStorage.setItem('data',JSON.stringify(this.value));
		   this.abc = localStorage.getItem('data');
		   console.log(JSON.parse(this.abc))
		   this.permission = JSON.parse(this.abc);
		   console.log(this.permission[0])
		   this.customer_view = this.permission[0].customer_view;
		   this.internal_dashboard = this.permission[0].internal_dashboard;
		   this.payment_dashboard = this.permission[0].payment_dashboard;

		   if(this.internal_dashboard == 1){
			this.router.navigate(['/maindashboard']);
		   }
		   
		   
		   else if(this.customer_view == 1){
			this.router.navigate(['/table-list']);
		   }
		   else if(this.payment_dashboard == 1){
			this.router.navigate(['/notifications']);
		   }
		  
		   
  
  
  
		  //  this.totalgoals = this.goals[0].number;
		},
		err => console.error(err),
	  );

	// if(this.data.username=='admin@gmail.com' && this.data.password=='admin12345'){
	// 		localStorage.setItem('admin', this.data.username);
	// 		console.log("success")
	// 		this.router.navigate(['/table-list']);
	// 	}
  	// else{
  	//    console.log("failed");
  	//    this.error=true;
  	// }
  }
 
}
